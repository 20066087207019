<template>
	<CModal title="Update status to On Service" :show.sync="modalState" color="info" size="md"> 
		<form @submit.prevent="approve">
    	<div class="pl-3 pr-3 pb-3"> 
    		<!-- <CRow class="mb-2">
        		<CCol lg="12">
        			Are you sure you want to approve this entry?
        		</CCol> 
        	</CRow>
			<br> -->
        	<CRow class="mb-2">
				<CCol lg="12">
        			<div class="form-group"> 
						<label>On Service Date & Time <span class="text-danger">*</span></label>
						<Datetime format="MM-DD-YYYY H:i" v-model="dataParams.history_wo_date" required></Datetime>
					</div>
        			
        		</CCol> 
        		<CCol lg="12">
        			<CTextarea
		                  label="Remarks"
		                  placeholder="Please enter remarks. Maximum of 255 only."
						  maxlength="250"
		                  v-model="dataParams.history_remarks" 
		                />
        		</CCol> 
        	</CRow>
        	
    	</div>
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit">
                Save
            </CButton> 
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="cancel">
                Close
            </CButton>  
        </div>
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datetime from 'vuejs-datetimepicker';
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import moment from 'moment';
	import '../../style.css';

	export default{

		props: {
			showModal: false,
			details: {
				remarks: ""
			}
		},
		data(){
			return{ 
				dataParams: {
					status: "on service"
				},
				modalState: false,
			}
		},
		components: {Datetime, vSelect},
		watch: {
			details: {
				deep: true,
				handler(){
					this.dataParams.history_remarks = this.details.remarks;
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {

			approve(){

				this.dataParams.history_wo_date = moment(this.dataParams.history_wo_date, "MM-DD-YYYY H:mm").valueOf() / 1000; 
				this.dataParams.history_description = "Updated status to on service"; 
				axios.put(config.api_path+'/workOrder/'+this.details.id, this.dataParams) 
    			Swal.fire({
					title: 'Success!',
					text: 'Work order successfully update status to on service!',
					icon: 'success', 
				})
				.then(response=>{
					this.$emit('showModal', false)
				})
			},
				cancel(){ 
				this.$emit('showModal', false) 
			},
			decline(){ 
	    		Swal.fire({
					//  title: 'Declined?',
						text: "Are you sure you want to decline this booking?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes,decline!'
						}).then((result) => {
						if (result.isConfirmed) {  
							this.dataParams.history_wo_date = Math.floor(Date.now() / 1000);
							this.dataParams.status = "declined"
							this.dataParams.history_description = "Updated status to declined"; 
							axios.put(config.api_path+'/workOrder/'+this.details.id, this.dataParams) 
			    			Swal.fire({
								title: 'Success!',
								text: 'Work order successfully update status to declined!',
								icon: 'success', 
							})
							.then(response=>{
								this.$emit('showModal', false)
							})
 						}
				})
				.then(response=>{
					// this.$emit('showModal', false)
				})
			}

		}

	}
</script>