<template>
    <div>
        <CCard class="p-4">
            <CCardHeader>
                <CRow>
                    <CButton size="sm" class="btn btn-link back-btn " @click="backToTable()"><i
                            class="fa fa-arrow-left"></i>Back</CButton>
                </CRow>
                <CRow style="color: #1352a1;">
                    <CCol lg="6">
                        <h5>Work Order/ #{{ dataParams.wo_no }}</h5>
                    </CCol>

                    <CCol lg="6">
                        <div style="text-align: right">
                            <CButton class="mr-1 btn btn-outline-primary" size="sm" @click="showHistory"><i
                                    class="fa fa-list"></i> History</CButton>
                            <!-- <CButton class="mr-1 btn btn-outline-primary" size="sm" @click="print"><i
                                    class="fa fa-print"></i> Print</CButton> -->
                            <CButton class="mr-1 btn btn-success" size="sm" @click="openApproveModal(dataParams)"
                                v-if="dataParams.status == 'submitted'"><i class="fa fa-thumbs-o-up"></i> Approve</CButton>
                            <CButton class="mr-1 btn btn-secondary" size="sm" @click="openOnServiceModal(dataParams)"
                                v-if="dataParams.status == 'approved'"><i class="fa fa-spinner"></i> On Service</CButton>
                            <CButton class="mr-1 btn btn-primary" size="sm" @click="openCompleteModal(dataParams)"
                                v-if="dataParams.status == 'on service'"><i class="fa fa-check-circle"></i> Complete
                            </CButton>
                            <!-- <CButton class="mr-1" color="btn btn-danger" size="sm" @click="backToTable()"><i class="fa fa-close"></i> Close</CButton> -->
                        </div>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody>
                <CCard class="card-view">
                    <CRow class="p-2 mb-2 bg-secondary text-dark">
                        <CCol lg="6">
                            <strong style="font-size:12px"> Order Details</strong>
                        </CCol>
                    </CRow>
                    <CRow class="ml-2 mr-2">
                        <CCol lg="4">
                            <div class="form-group">
                                <small class="">Email</small>
                                <label class="form-text mt-0"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.email ?
                                        dataParams.email : "N/A" }}
                                </label>
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div class="form-group">
                                <small class="">Date</small>
                                <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.date_needed ? moment.unix(dataParams.date_needed).format('MMMM DD, YYYY') : "N/A"}}</label>
                            </div>
                        </CCol>
                        <CCol lg="4">
							<div class="form-group">
                         	 <small class="">Status</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.status}}</label>
                        	</div>
							
						</CCol>  
                    </CRow>
                    <CRow class="ml-2 mr-2">
                        <CCol lg="4">
                            <div class="form-group">
                                <small class="">Depot</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.depot ?
                                        dataParams.depot.setting_name : "N/A" }}
                                </label>
                            </div>
                        </CCol>
                        <CCol lg="4">
                            <div class="form-group">
                                <small class="">Plate Number</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.vehicle ?
                                        dataParams.vehicle.plate_no : "N/A" }}
                                </label>
                            </div>
                        </CCol>
                        <CCol lg="4">
							<div class="form-group">
                         	 <small class="">Odometer</small>
                         	 <label class="form-text mt-0" style="font-size: 13px; font-weight: 600;">
								  {{dataParams.odometer_reading ? dataParams.odometer_reading.reading : "0"}} km</label>
								
							
                        	</div>
							
						</CCol>
						
						
                    </CRow>
                    <CRow class="ml-2 mr-2">
                        
                        <CCol lg="12">
                            <small class="">Scope of Work</small>
                            <CTextarea
                                id="exampleFormControlTextarea1"
                                rows="3"
                                v-model="dataParams.scope_of_work"
                                disabled
                            ></CTextarea>
                        </CCol>
                    </CRow>
                </CCard>
                <CCard class="card-view">
                    <CRow class="p-2 mb-1 bg-secondary text-dark">
                        <CCol lg="12">
                            <strong style="font-size:12px"> Material Request</strong>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow class="mb-1">
                        <CCol lg="12">
                          
		        			<CDataTable
							
						      	:items="work_order_part_request"
						      	:fields="[
								  {
										key: 'part_inventory_id_label', 
										label: 'Description'
									},
									{
										key: 'qty', 
										label: 'Qty'
									},
									{
										key: 'unit', 
										label: 'Unit Cost'
									},
									{
										key: 'total', 
										label: 'Total'
									}, 
									
									
								]"
								hover
								small
								outlined
								
						    >   
                           
                            </CDataTable>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow class="p-2 mb-1 bg-secondary text-dark">
                        <CCol lg="12">
                            <strong style="font-size:12px"> Labor Request</strong>
                        </CCol>
                    </CRow>
                    <CRow class="mt-2"> 
                            <CCol lg="12">
                                <small>Labor Description</small>
                                <CTextarea v-model="dataParams.labor_description" disabled />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="4">
                                <small>Labor Amount</small>
                                <CInput type="number" v-model="dataParams.labor_amount" disabled />
                            </CCol>
                        </CRow>
                    <CRow>
                        <CCol lg="8" class="text-right"></CCol>
                        <CCol lg="4" class="text-left">
                            <h6>Cost Summary</h6>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="8" class="text-right"></CCol>
                        <CCol lg="4" class="text-right">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex justify-content-between align-items-center py-1"
                                    style="font-size:14px">
                                    Labor
                                    <strong>{{ dataParams.labor_amount | number_format(null, 2) }}</strong>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center py-1"
                                    style="font-size:14px">
                                    Part
                                    <strong>{{ total_part_request | number_format(null, 2) }}</strong>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-center"
                                    style="font-weight:700; font-size:18px">
                                    TOTAL
                                    <strong> {{ computeTotal | number_format(null, 2) }} </strong>
                                </li>
                            </ul>
                        </CCol>
                    </CRow>
                </CCard>
                
                <CCard class="card-view">
                    <CRow class="p-2 mb-2 bg-secondary text-dark">
                        <CCol lg="6">
                            <strong style="font-size:12px"> Job Order Details </strong>
                        </CCol>
                    </CRow>
                    <CRow class="ml-3 mr-3">
                        <CCol lg="6">
                            <div class="form-group">
                                <small class="">Job Order Type - Purchase Order</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.purchase_order_job_order_type ? dataParams.purchase_order_job_order_type :
                                        "-No data-" }}</label>
                            </div>
                        </CCol>
                        <CCol lg="6">
                            <div class="form-group">
                                <small class="">Supplier Name (P.O)</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.po_vendor ? dataParams.po_vendor.vendor_name :
                                        "-No data-" }}</label>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow class="ml-3 mr-3">
                        <CCol lg="6">
                            <div class="form-group">
                                <small class="">Job Order Type - Cash Advance</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.cash_advance_job_order_type ? dataParams.cash_advance_job_order_type :
                                        "-No data-" }}</label>
                            </div>
                        </CCol>
                        <CCol lg="6">
                            <div class="form-group">
                                <small class="">Supplier Name (Cash)</small>
                                <label class="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">{{ dataParams.ca_vendor ? dataParams.ca_vendor.vendor_name :
                                        "-No data-" }}</label>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow class="ml-3 mr-3">
                        <CCol lg="12">
                            <div class="form-group">
                                <small class="">Supplier Quotation</small>
                                
                                <label v-if="dataParams.documents" dataclass="form-text mt-0 text-capitalize"
                                    style="font-size: 13px; font-weight: 600;">
                                    <a target="_blank" :href="main_path+'/work_order_documents/'+ dataParams.documents">{{ dataParams.documents }}</a>
                                </label>
                               
                                <label class="form-text" style="font-size: 13px; font-weight: 600;" v-else>
                                        -No document attached-
                                </label>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow class="ml-2 mr-2">
                        <CCol lg="12">
                            <small class="">Requestor Notes</small>
                            <CTextarea
                                rows="3"
                                v-model="dataParams.requestor_note"
                                disabled
                            ></CTextarea>
                        </CCol>
                    </CRow>
                </CCard>
            </CCardBody>
        </CCard>
        <CModal title="Work Order History" :show.sync="historyModal" size="lg" color="info">
            <CRow class="mb-1">
                <CCol lg="12">
                    <CDataTable :items="historyList" :fields="[
                        {
                            key: 'description',
                            label: 'Description'
                        },
                        {
                            key: 'user_label',
                            label: 'User'
                        },
                        {
                            key: 'wo_date_created',
                            label: 'Transaction Date'
                        },
                        {
                            key: 'remarks_label',
                            label: 'Remarks'
                        }
                    ]" hover small>
                        <template #description="{ item }">
                            <td style="padding: 5px;">
                                <span>{{ item.description }}</span><br />
                                <small><span>{{ moment.unix(item.wo_date).format('MMMM DD, YYYY hh:mm A') }}</span></small>

                            </td>
                        </template>

                    </CDataTable>
                </CCol>
            </CRow>

            <div slot="footer" class="w-100">
                <CButton @click="historyModal = false" style="border-radius: .2rem; color: white;" color="dark"
                    class="ml-1 mr-1 float-right">
                    Close
                </CButton>
            </div>
            <div slot="footer" class="w-100"></div>
        </CModal>
        <ApproveModal :details="dataParams" @showModal="approveModal = $event; getData();" :showModal="approveModal" />
        <OnServiceModal :details="dataParams" @showModal="onserviceModal = $event; getData();"
            :showModal="onserviceModal" />
        <CompleteModal :details="dataParams" @showModal="completeModal = $event; getData();" :showModal="completeModal" />
        <Print :dataParams="dataParams" :workOrderLaborRequest="work_order_labor_request"
            :workOrderPartRequest="work_order_part_request" :totalPartRequest="total_part_request"
            :totalLaborRequest="total_labor_request" />
    </div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';
import ApproveModal from './status_modal/approve';
import OnServiceModal from './status_modal/onservice';
import CompleteModal from './status_modal/complete';
import Print from './print';

export default {
    mounted() {

        this.getData();
        this.getLaborRequest();
        this.getPartRequest();

    },
    computed: {
        computeTotal() {
            let total = 0;
            total += this.dataParams.labor_amount ? parseFloat(this.dataParams.labor_amount) : 0;
            total += this.total_part_request ? parseFloat(this.total_part_request) : 0;
            return total;
        },

    },
    data() {
        return {
            main_path: config.main_path,
            dataList: [],
            historyList: [],
            historyModal: false,
            total_labor_request: 0.00,
            total_part_request: 0.00,
            approveModal: false,
            onserviceModal: false,
            completeModal: false,
            dataParams: {
                email:"",
                equipment_id: "",
                vehicle_id: "",
                furnishing_id: "",
                requested_by: "",
                subcon_id: "",
                asset_type: "vehicle",
                wo_type: "",
                location: "",
                service_summary: "",
                date_needed: "",
                remarks: "",
                srf_no: "",
                po_no: "",
                inv_no: "",
                status: "",
                wo_no: "",
                dept_id: "",
                assigned_to: "",
                vendor_id: "",

                vehicle: {
                    plate_no: ""
                },
                requester: {
                    name: ""
                }
            },
            work_order_labor_request: [],
            work_order_part_request: [],
            editMode: false,
            vendorList: {
                data: []
            },
            vehicleTypeList: {
                data: []
            },
            makerModelList: {
                data: []
            },
            depotList: {
                data: []
            },
            originList: {
                data: []
            },
            isLoading: false,
            uploadPercentage: 0,
            config,
            moment
        }
    },
    name: 'Tables',
    components: { Datepicker, vSelect, ApproveModal, OnServiceModal, CompleteModal, Print },
    methods: {
        showHistory() {
            this.historyModal = true
        },
        reload() {
            setTimeout(() => {
                this.getData();
            }, 500)
        },
        getData() {
            this.$showLoading(true)

            axios.get(config.api_path + "/workOrder/" + this.$route.params.id)
                .then(response => {
                    this.$showLoading(false)
                    this.dataParams = response.data.data;
                    console.log(this.dataParams);
                    // this.workOrderPartRequestList();
                    this.historyList = this.dataParams.work_order_history.map((value, index) => {
                        value.user_label = value.user.firstname + " " + value.user.lastname;

                        if (value.remarks && value.remarks != "") {
                            value.remarks_label = value.remarks;
                        }
                        else {
                            value.remarks_label = "-";
                        }
                        value.wo_date_created = moment(value.created_at).format('MMMM DD, YYYY h:mm:ss A')
                        return value
                    });
                })
                .catch(err => {
                    this.$showLoading(false)
                })
        },
        // workOrderPartRequestList(){
        //     this.dataParams.work_order_part_request = this.dataParams.work_order_part_request.map((value, index) => {
        //         let d = {
        //             description: value.part_inv.description,
        //             qty: value.qty,
        //             unit: value.unit,
        //             total: value.total,
        //         };
        //         return d;
        //     });
        // },
        openApproveModal(item) {
            this.approveModal = true;
            this.selectedRow = item;
        },

        openOnServiceModal(item) {
            this.onserviceModal = true;
            this.selectedRow = item;
        },


        openCompleteModal(item) {
            this.completeModal = true;
            this.selectedRow = item;
        },
        backToTable() {
            this.$router.push('/main/work_order')
        },

        updateModal(item) {
            this.$router.push('/main/update_booking/' + item.id)
        },

        viewDr(item) {
            this.$router.push('/main/view_booking/' + item.id + "/dr")
        },

        getLaborRequest() {
            this.$showLoading(true)

            axios.get(config.api_path + "/workOrderLaborRequest?work_order_id=" + this.$route.params.id)
                .then(response => {
                    this.$showLoading(false)
                    this.work_order_labor_request = response.data.data;
                    for (var i = 0; i < this.work_order_labor_request.length; i++) {
                        this.total_labor_request = parseFloat(this.total_labor_request)
                        this.total_labor_request += parseFloat(this.work_order_labor_request[i].total);
                    }
                })
        },

        getPartRequest() {
            this.$showLoading(true)
            axios.get(config.api_path + "/workOrderPartRequest?work_order_id=" + this.$route.params.id)
                .then(response => {
                    this.$showLoading(false)
                    this.work_order_part_request = response.data.data.map((value, index) => {
                        if (value.part_inventory_id) {
                            value.part_inventory_id_label = value.part_inv.part_name;
                        }
                        else {
                            value.part_inventory_id_label = "N/A";
                        }
                        return value;
                    });
                    for (var i = 0; i < this.work_order_part_request.length; i++) {
                        this.total_part_request = parseFloat(this.total_part_request)
                        this.total_part_request += parseFloat(this.work_order_part_request[i].total);
                    }
                })
                .catch(err => {
                    this.$showLoading(false)
                })

        },

        print() {

            var mywindow = window.open('', 'PRINT');

            mywindow.document.write('<html><head>');
            mywindow.document.write('</head><body >');
            mywindow.document.write(document.getElementById('work_order_print').innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();
        },

    }
}
</script>
