<template>
	<div id="work_order_print" style="display: none">
		<h5 style="text-align:center; text-transform: uppercase; font-size:16px">Work Order </h5>
		<table>
			<tr>
				<td width="500">
					Work Order #: <strong>{{dataParams.wo_no}}</strong>
				</td> 
				<td width="500">
					Date Needed: <strong>{{dataParams.date_needed ? moment.unix(dataParams.date_needed).format('MMMM DD, YYYY') : "N/A"}}</strong>
				</td> 
				
			</tr>
			<tr>
				<td width="500">
					Asset Type: <strong style="text-transform: uppercase;">{{dataParams.asset_type}}</strong>
				</td>
				<td width="500">
					Dept / Proj: <strong>{{dataParams.dept ? dataParams.dept.customer_name : "N/A"}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="500">
					WO Type: <strong>{{dataParams.wo_type ? dataParams.wo_type : "N/A"}}</strong>
				</td> 
				<td width="500">
					Location: <strong>{{dataParams.location}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="500">
					Assigned To: <strong>{{dataParams.assigned_to}}</strong>
				</td> 
				<td width="500" v-if="dataParams.assigned_to == 'subcon'">
					Subcon: <strong>{{dataParams.vendor_id ? dataParams.vendor.vendor_name : "N/A"}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="500">
					{{dataParams.asset_type == 'vehicle' ? 'Plate No.:' : 'Asset No.'}}
					<strong>{{dataParams.asset_type == 'vehicle' ? dataParams.vehicle.plate_no : (dataParams.asset_type == 'equipment' ? dataParams.equipment.asset_no : dataParams.furnishing.asset_no) }}</strong>
				</td>
				<td width="500">
					Requested By: <strong>{{dataParams.requested_by? dataParams.requester.name : dataParams.requester.name}}</strong>
				</td>
				
				
			</tr> 
			<tr>
				<td width="1000">
					Service Summary: <strong>{{dataParams.service_summary ? dataParams.service_summary : "-No data-"}}</strong>
				</td>
				
			</tr> 
		</table>
		<hr/> 
		<h4>Labor Request <span style="float: right">Total Labor Request: {{totalLaborRequest}}</span></h4>
		<table border="1">
			<tr style="-webkit-print-color-adjust:exact; background-color: black">
				<td width="225" style="color: white">
					Service Type
				</td> 
				<td width="225" style="color: white">
					Description
				</td>
				<td width="225" style="color: white">
					Qty
				</td>
				<td width="225" style="color: white">
					Unit Cost
				</td>
				<td width="225" style="color: white">
					Total
				</td>
				
			</tr> 
			<tr v-for="(item, index) in workOrderLaborRequest">
				<td width="225">
					<strong>{{item.service_type}}</strong>
				</td> 
				<td width="225">
					<strong>{{item.description}}</strong>
				</td>
				<td width="225">
					<strong>{{item.qty}}</strong>
				</td>
				<td width="225">
					<strong>{{item.unit}}</strong>
				</td>
				<td width="225">
					<strong>{{item.total}}</strong>
				</td>
				
			</tr>
		</table>
		<template v-if="workOrderPartRequest.length">
		<hr/>
		<h4>Part Request <span style="float: right">Total Part Request: {{totalPartRequest}}</span></h4>
		<table border="1">
			<tr style="-webkit-print-color-adjust:exact; background-color: black">
				<td width="225" style="color: white">
					Description
				</td>
				<td width="225" style="color: white">
					Qty
				</td>
				<td width="225" style="color: white">
					Unit Cost
				</td>
				<td width="225" style="color: white">
					Total
				</td> 
			</tr> 
			<tr v-for="(item, index) in workOrderPartRequest">
				<td width="225">
					<strong>{{item.part_inventory_id_label}}</strong>
				</td>
				<td width="225">
					<strong>{{item.qty}}</strong>
				</td>
				<td width="225">
					<strong>{{item.unit}}</strong>
				</td>
				<td width="225">
					<strong>{{item.total}}</strong>
				</td> 
			</tr>
		</table>
		</template>
		<br/>
		<br/>
		<br/>
		<br/>
		<br/>
		<br/>
		<br/>
		<br/>
		<br/>
		<table> 
			<tr>
				<td width="400"> 
					Performed By: <br/><br/><strong>{{dataParams.performed_by ? dataParams.performed_by : "N/A"}}</strong>
				</td>
				<td width="400"> 
					Checked By: <br/><br/><strong>{{dataParams.checked_by ? dataParams.checked_by : "N/A"}}</strong>
				</td> 
				<td width="400"> 
					Confirmed By: <br/><br/><strong>{{dataParams.confirmed_by ? dataParams.confirmed_by : "N/A"}}</strong>
				</td> 
				<td width="400"> 
					Noted By: <br/><br/><strong>{{dataParams.noted_by ? dataParams.noted_by : "N/A"}}</strong> 
				</td> 
			</tr> 
		</table>
		<!-- 
		<br>
			<div >
				Remarks: <strong>{{dataParams.remarks? dataParams.remarks : "N/A"}}</strong>
			</div>
					
			
		<br/>
		<hr/>
		<br/>
		<h4>References</h4>
		<br><br>
		<table>
			<tr>
				<td width="500">
					Prepared By: <strong>{{dataParams.prepared_by ? dataParams.prepared_by : "N/A"}}</strong>
				</td>
				<td width="500">
					Approved By: <strong>{{dataParams.approved_by ? dataParams.approved_by : "N/A"}}</strong>
				</td> 
			</tr> 
		</table>  -->
	</div>
</template>
<script>
	import moment from 'moment'; 
	export default {
		mounted(){   

		},
		data(){
			return{
				moment
			}
		},
		props: {
			dataParams: {
				equipment_id: "",
				vehicle_id: "",
				furnishing_id:"",
				requested_by:"",
				subcon_id:"",
				asset_type:"vehicle",
				wo_type:"",
				location:"", 
				service_summary:"",
				date_needed:"",
				remarks:"",
				srf_no:"",
				po_no:"",
				inv_no:"",
				status:"",
				wo_no:"",
				dept_id: "",
				assigned_to: "",
				vendor_id: "",
				
				vehicle: {
					plate_no: ""
				},
				requester: {
					name: ""
				}
			},
			workOrderLaborRequest: [ ],
			workOrderPartRequest: [ ],
			totalPartRequest: "",
			totalLaborRequest: ""
		},
	}
</script>